import React from 'react';
import { JSXChildren } from '../../../../common/utils';
import { Subject } from 'rxjs';
import { isMobile } from 'react-device-detect';
import VideoPlayer from '../../../../common/components/VideoPlayer/VideoPlayer';
import { IHero } from '../Hero';
import { HeroPartial } from '../Hero-partial';
import ModalContainer from '../../../../common/components/Modal/Container/Container';


export class HeroPlayOnModalPartial {
  static videoPlayerView(url: string, event: Subject<void>) {
    return (
      <ModalContainer event={event}>
        <VideoPlayer
          style={{ width: '100%' }}
          url={url}
          height={isMobile ? '70vh' : '100%'}
          width="100%"
          controls={true} />
      </ModalContainer>
    );
  }

  static mobileImageContainer({ thumbnail, buttonText, url }: IHero,
    triggerEvent: (event: React.MouseEvent) => void,
    buttonEvent: Subject<void>) {
    return (
      <div className="Hero__info--image__front"
        style={{
          width: "100%",
          height: "70vh",
          backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), url(${thumbnail})`
        }}>
        <div className="Hero__info--container" />
        {HeroPlayOnModalPartial.videoPlayerView(url, buttonEvent)}
      </div>
    );
  }

  static mobileView({ title, shareLink, shareText }: IHero, children: JSXChildren) {
    return (
      <>
        {children}
        <div className="Hero__info--container Hero__info--container-mobile">
          <div className="Hero__info">
            <h1>{title}</h1>
          </div>
        </div>
      </>
    );
  }

  static normalView(props: IHero, triggerEvent: (event: React.MouseEvent) => void
    , buttonEvent: Subject<void>) {
    return (
      <>
        <div className="Hero__info--image__front"
          style={{
            backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), url(${props.thumbnail})`
          }}>
          <div className="Hero__info--container">
            <div className="Hero__info">
              <h1>{props.title}</h1>
            </div>
          </div>

        </div>
        {HeroPlayOnModalPartial.videoPlayerView(props.url, buttonEvent)}
      </>
    );
  }
}
