import { JSXChildren } from '../../../../common/utils';
import { Subject } from 'rxjs';
import { IHero } from '../Hero';

abstract class HeroStrategy {
  constructor(
    protected readonly props: IHero,
    protected readonly triggerClickEvent: (event: React.MouseEvent) => void,
    protected readonly buttonEvent: Subject<void>) { }

  abstract renderVideoOnMobile(): JSXChildren;
  abstract renderPlaceholderOnMobile(): JSXChildren;
  abstract renderNormalView(): JSXChildren;
  abstract render(showPlayer: boolean): JSXChildren;
}

export default HeroStrategy;