import React, { useState } from 'react';
import { MdClear } from 'react-icons/md';
import './Modal.scss';
import { Subject } from 'rxjs';
import { JSXChildren } from '../../utils';
import useSubscriptionsManager from '../../hooks/subscriptions';

interface IModal {
  toggleEvent?: Subject<void>;
  children?: JSXChildren
}

const Modal = (props: IModal) => {
  const [open, setOpen] = useState(false);
  const addSubscription = useSubscriptionsManager([]);

  const subscription = props.toggleEvent?.subscribe({
    next: () => toggle()
  });

  if (subscription) {
    addSubscription(subscription);
  }

  function toggle(): void {
    setOpen(!open);
  };

  function toggleWithClose(): void {
    toggle();
    props.toggleEvent?.next();
  }

  return (
    <div className="Modal" >
      <span
        className="Modal__close"
        onClick={toggleWithClose}>
        <MdClear />
      </span>
      {props.children}
    </div>
  );
}

export default Modal;