import React from 'react';
import { Subject } from 'rxjs';
import { HeroPartial } from '../Hero-partial';
import { JSXChildren } from '../../../../common/utils';
import { IHero } from '../Hero';
import HeroStrategy from './HeroStrategy';
import { HeroPlayOnModalPartial } from './HeroPlayOnModalStrategyPartial';
import { isMobile } from 'react-device-detect';

export class HeroPlayOnModalStrategy extends HeroStrategy {
  constructor(
    public readonly props: IHero,
    public readonly triggerClickEvent: (event: React.MouseEvent) => void,
    public readonly buttonEvent: Subject<void>) {
    super(props, triggerClickEvent, buttonEvent);
  }

  renderVideoOnMobile(): JSXChildren {
    throw new Error("Method not implemented for this strategy.");
  }

  renderPlaceholderOnMobile(): JSXChildren {
    const videoPlaceholder = HeroPlayOnModalPartial.mobileImageContainer(this.props,
      this.triggerClickEvent, this.buttonEvent);
    const mobileView = HeroPlayOnModalPartial.mobileView(this.props, videoPlaceholder);
    return HeroPartial.heroContainer(mobileView);
  }

  renderNormalView(): JSXChildren {
    const normalView = HeroPlayOnModalPartial.normalView(this.props,
      this.triggerClickEvent, this.buttonEvent);
    const backgroundBase = HeroPartial.bgBase(this.props.thumbnail, normalView);
    return HeroPartial.heroContainer(backgroundBase);
  }


  render(_: boolean) {
    return isMobile ? this.renderPlaceholderOnMobile() : this.renderNormalView();
  }
}