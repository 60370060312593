import React from 'react';
import { JSXChildren } from '../../../../common/utils';
import { Subject } from 'rxjs';
import { isMobile } from 'react-device-detect';
import VideoPlayer from '../../../../common/components/VideoPlayer/VideoPlayer';
import { IHero } from '../Hero';
import { HeroPartial } from '../Hero-partial';


export class HeroNormalPartial {
  static videoPlayerView({ url, thumbnail }: IHero) {
    return (
      HeroPartial.heroContainer(
        HeroPartial.bgBase(
          thumbnail,
          <VideoPlayer
            style={{ width: '100%' }}
            url={url}
            height={isMobile ? '70vh' : '100%'}
            width="100%"
            controls={true} />
        )
      )
    );
  }

  static mobileImageContainer({ thumbnail, buttonText }: IHero,
    triggerEvent: (event: React.MouseEvent) => void,
    buttonEvent: Subject<void>) {
    return (
      <div className="Hero__info--image__front"
        style={{
          width: "100%",
          height: "250px",
          backgroundPosition: "cover",
          backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), url(${thumbnail})`
        }}>
        <div className="Hero__info--container" />
      </div>
    );
  }

  static mobileView(props: IHero, children: JSXChildren) {
    return (
      <>
        {children}
      </>
    );
  }

  static normalView(props: IHero, triggerEvent: (event: React.MouseEvent) => void
    , buttonEvent: Subject<void>) {
    return (
      <div className="Hero__info--image__front"
        style={{
          backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), url(${props.thumbnail})`
        }}>
        <div className="Hero__info--container">
          <div className="Hero__info" />
        </div>
      </div>
    );
  }

  static showPlayer({ url, thumbnail }: IHero) {
    return(
      <>
        {HeroPartial.heroContainer(
          HeroPartial.bgBase(
            thumbnail,
            <VideoPlayer
              style={{ width: '100%' }}
              url={url}
              height='100%'
              width="100%"
              controls={true} />
          )
        )}
      </>
    );
  }

  static normalViewNoPlayer(props: IHero, triggerEvent: (event: React.MouseEvent) => void
  , buttonEvent: Subject<void>) {
    return (
      <>
        <div className="Hero__displayLargeScreen Hero">
          <div className="Hero__info--image__bg"
            style={{ backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${props.thumbnail})` }} />

          <div className="Hero__info--image__front"
            style={{
              backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), url(${props.thumbnail})`
            }}>
            <div className="Hero__info--container">
              <div className="Hero__info" />
            </div>
          </div>
        </div>

        <div className="Hero__displayMobile Hero__info--image__front"
          style={{
            width: "100%",
            height: "150px",
            backgroundPosition: "cover",
            backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), url(${props.thumbnail})`
          }}>
          <div className="Hero__info--container" />
        </div>
      </>
    );
  }
}
