import './Categories.scss';
import { ITile } from '../../../common/models/tile';
import { isMobile, isTablet } from 'react-device-detect';
import CategoriesPartial from './Categories-partial';
import { Constants } from '../../../common/constants';
import useSubscriptionsManager from '../../../common/hooks/subscriptions';
import trackEvent from '../../../common/services/analytics.service';

export interface ICategories {
  title: string;
  list: ITile[];
  viewAll: string;
}
const Categories = (props: ICategories) => {
  const subs = props.list.map(x => x.event?.subscribe({
    next: () => {
      trackEvent(Constants.Segment.Category.Category, {
        category: Constants.Segment.Category.Category,
        action: x.title,
        label: window.location.pathname
      });
    }
  }));

  useSubscriptionsManager(subs);

  return isMobile && !isTablet ? CategoriesPartial.mobileView(props) :
    CategoriesPartial.normalView(props);
}

export default Categories;