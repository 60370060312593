import React from 'react';
import { Link } from '@reach/router';
import './Tile.scss';
import { ITile } from '../../models/tile';
import { Constants } from '../../constants';
import trackEvent from '../../services/analytics.service';

const Tile = (props: ITile) => {
  const image =
    `url(${props.imageUrl})`;

  const handleClick = () => {
    trackEvent(props.title, {
      label: props.title,
      category: Constants.Segment.Category.Category,
      action: props.title
    });

  }
  return (
    <Link className="Tile" to={props.linkUrl} onClick={handleClick}>
      <div className="Tile__content"
        style={{ backgroundImage: image }}>
        <div className="Tile__inner" />
        <h3 className={(props.title.length < 10) ? "Tile__title" : "Tile__title_s"}>
          {props.title}
        </h3>
      </div>
    </Link>
  );
}

export default Tile;
