import React from 'react';
import TileList from '../../../common/components/Tile/List/List';
import { Link } from '@reach/router';
import { ICategories } from './Categories';
import { JSXChildren } from '../../../common/utils';

class CategoriesPartial {
  static container(children: JSXChildren) {
    return <div className="Categories">{children}</div>;
  }

  static mobileView({ list, title, viewAll }: ICategories) {
    return CategoriesPartial.container(
      <>
        <p className="Categories__title">{title}</p>
        <TileList tiles={list} />
        <Link className="Categories__link" to={viewAll}><strong>View all &gt;</strong></Link>
      </>
    );
  }

  static normalView({ list }: ICategories) {
    return CategoriesPartial.container(<TileList tiles={list} />);
  }
}

export default CategoriesPartial;