import React from 'react';
import './List.scss';
import Tile from '../Tile';
import { ITile } from '../../../models/tile';

interface ITileList {
  tiles: ITile[]
}

const TileList = (props: ITileList) => {
  const { tiles } = props;
  return <div className={tiles.length > 6 ? "List__s" : "List"}>
    {tiles.map((x, i) => <Tile key={i} {...x} />)}
  </div>
};

export default TileList;