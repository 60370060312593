import { Subject } from 'rxjs';
import { HeroNormalPartial } from './HeroNormalStrategyPartial';
import { JSXChildren } from '../../../../common/utils';
import { IHero } from '../Hero';
import HeroStrategy from './HeroStrategy';
import { HeroPartial } from '../Hero-partial';

export class HeroNormalStrategy extends HeroStrategy {
  constructor(
    public readonly props: IHero,
    public readonly triggerClickEvent: (event: React.MouseEvent) => void,
    public readonly buttonEvent: Subject<void>) {
    super(props, triggerClickEvent, buttonEvent);
  }

  renderVideoOnMobile(): JSXChildren {
    const videoPlayer = HeroNormalPartial.videoPlayerView(this.props);
    const mobileView = HeroNormalPartial.mobileView(this.props, videoPlayer);
    return HeroPartial.heroContainer(mobileView);
  }

  renderPlaceholderOnMobile(): JSXChildren {
    const videoPlaceholder = HeroNormalPartial.mobileImageContainer(this.props,
      this.triggerClickEvent, this.buttonEvent);
    const mobileView = HeroNormalPartial.mobileView(this.props, videoPlaceholder);
    return HeroPartial.heroContainer(mobileView);
  }

  renderNormalView(): JSXChildren {
    const normalView = HeroNormalPartial.normalView(this.props,
      this.triggerClickEvent, this.buttonEvent);
    const backgroundBase = HeroPartial.bgBase(this.props.thumbnail, normalView);
    return HeroPartial.heroContainer(backgroundBase);
  }

  render(showPlayer: boolean) {
    let chosen: JSXChildren;

    chosen = showPlayer ? HeroNormalPartial.showPlayer(this.props) : HeroNormalPartial.normalViewNoPlayer(this.props, this.triggerClickEvent, this.buttonEvent);

    return chosen;
  }
}