import React, { Component } from 'react';
import ReactPlayer from 'react-player/lazy'
import './VideoPlayer.scss';

type Url = string | string[] | { src: string, type: string }[] | MediaStream;
interface IVideoPlayer {
  url: Url,
  width: string,
  height: string,
  style?: {},
  light?: boolean,
  controls?: boolean,
  playIcon?: JSX.Element,
}

class VideoPlayer extends Component<IVideoPlayer> {
  render() {
    return (
      <div className="VideoPlayer__wrapper">
        <ReactPlayer
          {...this.props}
        />
      </div >
    )
  }
}

export default VideoPlayer;