import React from 'react';
import { Link } from 'gatsby';
import './Heading.scss';
import { Constants } from '../../../common/constants';
import trackEvent from '../../../common/services/analytics.service';

export interface IHeading {
  title: string;
  subtitle: string;
  viewAllText: string;
  viewAllLink?: string;
}

const Heading = (props: IHeading) => {
  const trackViewAll = () => {
    trackEvent(Constants.Segment.Action.ViewAll, {
      category: Constants.Segment.Category.Navigation,
      label: window.location.pathname,
      action: Constants.Segment.Action.ViewAll
    });
  }

  const viewAll = props.viewAllLink ?
    <Link to={props.viewAllLink} onClick={trackViewAll}>
      {props.viewAllText}
    </Link> : undefined;

  const children = [
    <p className="App__h1 TopHeading__title" key="heading__title">{props.title}</p>,
    <p className="App__h4 TopHeading__subtitle" key="heading__subtitle">{props.subtitle}&nbsp;&nbsp;{viewAll}</p>
  ];

  return (
    <div>
      <div className="TopHeading">
        {children}
      </div>
    </div>
  );
}

export default Heading;
