import React, { useState } from 'react';
import './Hero.scss';
import { Subject } from 'rxjs';
import { HeroPartial } from './Hero-partial';
import { JSXChildren } from '../../../common/utils';
import useSubscriptionsManager from '../../../common/hooks/subscriptions';

export interface IHero {
  title: string,
  url: string,
  thumbnail: string,
  shareText: string,
  shareLink: string,
  buttonText: string;
  playOnModal?: boolean;
  modalEvent?: Subject<JSXChildren>;
}


function Hero(props: IHero) {
  const buttonEvent = new Subject<void>();
  const behaviorStrategy = HeroPartial.chooseBehaviorStrategy(props, triggerClickEvent, buttonEvent);
  const [showPlayer, setShowPlayer] = useState(false);
  const addSubscription = useSubscriptionsManager([]);

  const subs = buttonEvent
    .subscribe({
      next: () => setShowPlayer(true)
    });

  addSubscription(subs);

  function triggerClickEvent(event: React.MouseEvent) {
    event.preventDefault();
    buttonEvent.next();
  }

  return behaviorStrategy.render(showPlayer) as JSX.Element;
}

export default Hero;