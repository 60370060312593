import React from 'react';
import { JSXChildren } from '../../../common/utils';
import { isMobile } from 'react-device-detect';
import { MdPlayArrow } from 'react-icons/md';
import Button from '../../../common/components/Button/Button';
import { EnumButtonType } from '../../../common/enums/EnumButtonType';
import { Subject } from 'rxjs';
import { IHero } from './Hero';
import { HeroPlayOnModalStrategy } from './strategy/HeroPlayOnModal';
import { HeroNormalStrategy } from './strategy/HeroNormalStrategy';

export class HeroPartial {
  static chooseBehaviorStrategy(props: IHero,
    triggerClickEvent: (event: React.MouseEvent) => void,
    buttonEvent: Subject<void>) {
    return props.playOnModal ? new HeroPlayOnModalStrategy(props, triggerClickEvent, buttonEvent) :
      new HeroNormalStrategy(props, triggerClickEvent, buttonEvent);
  }

  static heroContainer(children: JSXChildren): JSXChildren {
    return <div className="Hero">{children}</div>;
  }

  static button({ buttonText }: { buttonText: string },
    tiggerEvent: (event: React.MouseEvent) => void,
    buttonEvent: Subject<void>) {
    if (isMobile) {
      return <MdPlayArrow
        style={{ borderRadius: "50px", backgroundColor: "rgba(255,255,255,0.3)" }}
        color="#fff" size="80px"
        onClick={tiggerEvent} />
    } else {
      return <Button
        text={buttonText}
        type={EnumButtonType.PrimaryAction}
        event={buttonEvent} />
    }
  }

  static bgBase(thumbnail: string, children: JSXChildren) {
    return <>
      <div className="Hero__info--image__bg"
        style={{ backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${thumbnail})` }} />
      {children}
    </>;
  };
}