import React from "react"
import { graphql } from "gatsby"

import '../fonts/BogleWeb-Bold.ttf';
import '../fonts/BogleWeb-Regular.ttf';
import Home from '../core/screens/home/Home';
import SEO from '../core/components/SEO/seo';
import { Subject } from "rxjs";
import { ICard } from '../common/components/Card/Card';
import { Constants } from '../common/constants';
import detectIframe from "../common/services/detect-iframe.service";

interface LandingPage {
  data: {
    site: {
      siteMetadata: {
        env: string
      }
    },
    allWordpressWpResources: {
      nodes: {
        id: string;
        wordpress_id: number;
        slug: string;
        acf: {
          resource_title: string;
          resource_subtitle: string;
          resource_logo: {
            source_url: string;
          }
        }
      }[];
    }
    wordpressPage: {
      title: string;
      acf: {
        categories_grid: {
          link: string;
          text: string;
          background_image: {
            source_url: string;
          }
        }[];
        categories_headline: string;
        categories_link_text: string;
        featured_resources: {
          resource: {
            wordpress_id: number;
          }
        }[];
        header_headline: string;
        header_link_text: string;
        header_sub_headline: string;
        image_background: {
          source_url: string;
        };
        image_caption: string;
        image_share_text: string;
      }
      yoast_meta: {
        yoast_wpseo_title: string;
        yoast_wpseo_metadesc: string;
        yoast_wpseo_facebook_image: string;
        yoast_wpseo_facebook_description: string;
        yoast_wpseo_facebook_title: string;
        yoast_wpseo_twitter_title: string;
        yoast_wpseo_twitter_description: string;
        yoast_wpseo_twitter_image: string;
      }
    }
  }
}

const Homepage = (props: LandingPage) => {
  const data = props.data;
  const acf = data.wordpressPage.acf;
  const seo = data.wordpressPage.yoast_meta
  const env = data.site.siteMetadata.env
  
  if(!detectIframe(Constants.WalmartCaresBaseUrl, env)) {
    return (null)
  }
  
  const resources = data.allWordpressWpResources.nodes
    .filter(x =>
      acf.featured_resources.findIndex(y => y.resource.wordpress_id === x.wordpress_id) > -1)
    .map(x => {
      const resource: ICard = {
        imageUrl: x.acf.resource_logo && x.acf.resource_logo.source_url ? x.acf.resource_logo.source_url: "",
        linkUrl: `${Constants.Paths.resources}${x.slug}`,
        title: x.acf.resource_subtitle,
        subtitle: x.acf.resource_title,
        buttonEvent: new Subject<void>()
      }

      return resource;
    });

  const categories = acf.categories_grid.map(x => {
    return {
      event: new Subject<void>(),
      imageUrl: x.background_image && x.background_image.source_url ? x.background_image.source_url : "",
      linkUrl: x.link,
      title: x.text
    }
  });

  return(
    <>
      <SEO
        title={`${data.wordpressPage.title} - My Walmart Cares`}
        description={seo.yoast_wpseo_metadesc}
        image={seo.yoast_wpseo_facebook_image ? seo.yoast_wpseo_facebook_image.source_url : ''}
        twitterDescription={seo.yoast_wpseo_twitter_description}
        twitterTitle={seo.yoast_wpseo_twitter_title}
        twitterImage={seo.yoast_wpseo_twitter_image ? seo.yoast_wpseo_twitter_image.source_url : ''}
        facebookDescription={seo.yoast_wpseo_facebook_description}
        facebookTitle={seo.yoast_wpseo_facebook_title}
      />
      <Home
        categories={{
          viewAll: acf.categories_link_text,
          title: acf.categories_headline,
          list: categories,
        }}
        heading={{
          subtitle: acf.header_sub_headline,
          title: acf.header_headline,
          viewAllLink: '/resources/',
          viewAllText: acf.header_link_text
        }}
        hero={{
          buttonText: Constants.GeneralStrings.watch,
          shareLink: "/",
          shareText: acf.image_share_text,
          thumbnail: acf.image_background.source_url,
          title: acf.image_caption,
          url: ""
        }}
        resources={{
          list: resources,
          title: Constants.GeneralStrings.featureResources,
          viewAll: true,
        }}
      />
    </>
  )
}

export default Homepage;
export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        env
      }
    }
    allWordpressWpResources {
      nodes {
        id
        wordpress_id
        slug
        acf {
          resource_title
          resource_subtitle
          resource_logo {
            source_url
          }
        }
      }
    }
    wordpressPage(title: { eq: "Homepage" }){
      title
      acf {
        categories_grid {
          link
          text
          background_image {
            source_url
          }
        }
        categories_headline
        categories_link_text
        featured_resources {
          resource {
            wordpress_id
          }
        }
        header_headline
        header_link_text
        header_sub_headline
        image_background {
          source_url
        }
        image_caption
        image_share_text
      }
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
        yoast_wpseo_facebook_image
        yoast_wpseo_facebook_title
        yoast_wpseo_facebook_description
        yoast_wpseo_twitter_title
        yoast_wpseo_twitter_description
        yoast_wpseo_twitter_image
      }
    }
  }
`;
