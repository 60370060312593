import React, { useState } from 'react';
import { Subject } from 'rxjs';
import Modal from '../Modal';
import { JSXChildren } from '../../../utils';
import useSubscriptionsManager from '../../../hooks/subscriptions';

interface IModalContainer {
  event: Subject<void>;
  children?: JSXChildren;
}

const ModalContainer = (props: IModalContainer) => {
  const [showModal, setShowModal] = useState(false);
  const addSubscription = useSubscriptionsManager([]);

  const subscription = props.event?.subscribe({
    next: () => {
      setShowModal(!showModal);
    }
  });

  addSubscription(subscription);

  return !showModal ? <div className="ModalContainer" /> :
    (
      <div className="ModalContainer">
        <Modal toggleEvent={props.event}>
          {props.children}
        </Modal>
      </div>
    );
}

export default ModalContainer;