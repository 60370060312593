import React from 'react';
import Hero, { IHero } from '../../components/Hero-video/Hero';
import Heading, { IHeading } from '../../components/Heading/Heading';
import Categories, { ICategories } from '../../components/Categories/Categories';
import Container from '../../../common/components/Container/Container';
import ResourceList, { IResourceList } from '../../components/ResourceList/ResourceList';
import { Subject } from 'rxjs';

interface IHome {
  heading: IHeading,
  hero: IHero,
  categories: ICategories,
  resources: IResourceList
}

const Home = (props: IHome) => {
  const { heading, hero, categories, resources } = props;
  resources?.list.forEach(x => x.buttonEvent = new Subject<void>());
  return (
    <div>
      <Hero {...hero} />
      <Heading {...heading} />
      <div style={{ position: "relative", backgroundColor: 'white' }}>
        <Container>
          <Categories {...categories} />
        </Container>
      </div>
      <div style={{ marginTop: "32px" }}>
        <div style={{ maxWidth: "1024px", margin: "0 auto" }}>
          <ResourceList {...resources} />
        </div>
      </div>
    </div>
  );
}

export default Home;
